<template>
    <base-nav class="navbar-top navbar-dark" id="navbar-main" :show-toggle-button="true" expand>
        <ul class="navbar-nav align-items-center d-none d-md-flex mr-3 ml-md-auto">
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0">
                    <div class="media align-items-center" slot="title">
                        <div class="media-body ml-2 d-none d-md-block">
                            <user-name :user="user.utilisateur" class="username" :can-display-details="false"/>
                        </div>
                    </div>

                    <template>
                        <dashboard-navbar-links/>
                    </template>
                </base-dropdown>
            </li>
        </ul>
    </base-nav>
</template>

<script>
    import {mapState} from 'vuex';

    const DashboardNavbarLinks = () => import('./DashboardNavbarLinks');
    const UserName             = () => import('@/components/UserName');

    export default {
        name: 'DashboardNavbar',
        components: {DashboardNavbarLinks, UserName},
        data: () => ({
            activeNotifications: false,
            showMenu: false,
            searchQuery: '',
        }),
        computed: {
            ...mapState({user: 'user'})
        },
        methods: {
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false);
            },
            toggleMenu() {
                this.showMenu = !this.showMenu;
            }
        }
    };
</script>

<style scoped>
    .navbar-nav li {
        cursor: pointer;
    }

    ul .username {
        filter: drop-shadow(0 0 2px black);
    }
</style>
